*{
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family: 'Sora', sans-serif;


}

header{
  display: flex;
  padding:8px 10px;
  justify-content: space-around;
  border-bottom:2px solid #2a5bd7;
}

header .logo h3{
  font-size: 23px;
  color:#333;
}
header a{
  margin:0 10px;
  color: #2a5bd7;
  text-decoration: none;
}
header a.btn-entrar-menu{
  background-color: #2a5bd7;
  display: inline-block;
  padding:8px 10px;
  border-radius:8px;
  color:white;
  font-weight: bold;
  text-decoration: none;
}

.bannerBg{
  padding: 50px 0;
  background-color: #3f51b5;
  text-align: center;
  color:white;
}

.bannerBg h3{
  font-size: 40px;
}

a.btnbuy{
  background-color: #2a5bd7;
  display: inline-block;
  padding:10px 20px;
  border-radius:8px;
  font-size: 20px;
  color:white;
  font-weight: bold;
  text-decoration: none;
}

.bannerBg a{
  background-color: #009d43;
  display: inline-block;
  padding:10px 20px;
  border-radius:8px;
  font-size: 20px;
  color:white;
  font-weight: bold;
  text-decoration: none;
}

.testdrive{
  max-width:800px;
  margin:20px auto;
  padding:0 10px;
  text-align: center;
}

.testdrive input[type=text]{
  width:100%;
  height:40px;
  border:1px solid #ccc;
  padding-left: 10px;
}

.testdrive input[type=submit]{
  width:100px;
  height:40px;
  cursor: pointer;
  background-color: #2a5bd7;
  border: 0;
  color:white;
}

.love{text-align: center;}
.love-brands{
  justify-content: center;
  align-items: center;
  display: flex;
}

.love-brands img{
  margin:0 10px;
}

.header-logado{
  display: flex;
  padding:15px 0;
  border-bottom:2px solid #ccc;
  justify-content: space-around;

}

.header-logado img{
  width:30px;
  height:30px;
  border-radius:15px;
}

.header-logado h3{
  color:#333;
  font-size: 19px;
}

.boxPagto{
    width:100%;
    
    margin:0 10px;
    padding:20px 10px;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.12),0 4px 5px 0 rgba(0,0,0,.1),0 1px 10px 0 rgba(0,0,0,.08);
}

.boxPagtoAll{
  margin:10px auto;
  max-width: 900px;
 
 
}

.links{
  margin:50px auto;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:0 2%;
  
}

.links h2{
  width:100%;
  color: #333;
  border-bottom: 2px solid #333;
}

.links > div{
  width:100%;
  margin-top:10px;
}

.links a{
  color: #333;
  text-decoration: none;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.12),0 4px 5px 0 rgba(0,0,0,.1),0 1px 10px 0 rgba(0,0,0,.08);
  background: rgba(220,220,220);
  width:100%;
  display: block;
  margin:3px 0;
}

.input-encurtador{
  margin:50px auto;
  max-width: 900px;
  display: flex;
  
  align-items: center;
  justify-content: center;
  padding:0 2%;
}

.input-encurtador form{width:100%;}

.input-encurtador input[type=text]{
  width: calc(100% - 120px);
  height:40px;
  padding-left: 10px;
  border:1px solid #ccc;
}

.input-encurtador input[type=submit]{
  width:120px;
  height:40px;
  background-color: #2a5bd7;
  border: 0;
  color:white;
  cursor: pointer;
}